import '@fortawesome/fontawesome-svg-core/styles.css'
import 'tailwindcss/tailwind.css'
import '../../../global.css'

import { config } from '@fortawesome/fontawesome-svg-core'
import { withAuth } from '@invisible/authentication/client'
import { withAbilityContext } from '@invisible/authorization/client'
import { createApp } from '@invisible/common/providers'
import { withTRPC } from '@invisible/trpc/client'
import { LicenseInfo } from '@mui/x-license'

import { Layout } from '../components/Layout'

LicenseInfo.setLicenseKey(
  '52ba9f985f3e06f888c913a8f6134592Tz05MTQ3MSxFPTE3NDg1MjczODQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
)

config.autoAddCss = false // TODO: get rid of all fontawesome icons and then remove this

export default createApp({ Layout: withAbilityContext(withAuth(withTRPC(Layout))) })
